import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({providedIn: 'root'})
export class ApiDenoService {
  public baseUrl = 'https://one-dbe.deno.dev/api'
  // public baseUrl = 'http://localhost:4242/api'

  constructor(
    private http: HttpClient,
  ) { }

  public get<T>(url: string, headers?: HttpHeaders): Observable<T> {
    const options = headers ? { headers } : {}
    return this.http.get<T>(`${this.baseUrl}/${url}`, options)
  }

  public post<T>(url: string, payload: any, headers?: HttpHeaders): Observable<T> {
    const options = headers ? { headers } : {}
    return this.http.post<T>(`${this.baseUrl}/${url}`, payload, options);
  }

  public put<T>(url: string, payload: any): Observable<T>  {
    return this.http.put<T>(`${this.baseUrl}/${url}`, payload)
  }

  public delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}/${url}`)
  }

  public patch<T>(url: string, payload: any): Observable<T>  {
    return this.http.patch<T>(`${this.baseUrl}/${url}`, payload)
  }

  public kvSet<T>(key: string, value: any, token: string): Observable<T> {
    if (!token) {
      throw new Error('no dbe_token')
    }
    const header = new HttpHeaders().set('X-KV-Token', token)
    return this.post<T>(`kv/${key}`, value, header)
  }

  public kvGet<T>(key: string, token: string): Observable<T> {
    if (!token) {
      throw new Error('no dbe_token')
    }
    const header = new HttpHeaders().set('X-KV-Token', token)
    return this.get<T>(`kv/${key}`, header)
  }

}
