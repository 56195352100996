import { Injectable } from '@angular/core'
import { UserService } from '../user.service'
import { RouteHeaderUrl } from '../../../shared/components/one-header/header-navigation.model'
import moment from 'moment'
import { StripeSubscription } from '../../../pages/cart/models/cart-models'
import { ProductPageConfig } from '../../../pages/cart/services/products-page-config'
import { BehaviorSubject, map, Observable, Subscription, switchMap } from 'rxjs'
import { CartService } from '../../../pages/cart/services/cart.service'
import { LimitItem } from '../../../shared/components/one-header/components/one-main-nav-limits/one-main-nav-limits.component'
import { ApiDenoService } from '../api/api-deno.service'
import { ApiService } from '../api/api.service'

export interface FeatureStatus {
  limit: number | null
  used: number | null
  blocked: boolean
  upgradeUrl: string
}

export interface StripeStatsResponseItem {
  used: number
  wl: boolean
}

export interface StripeStatsResponse {
  [RouteHeaderUrl.emails]: StripeStatsResponseItem
  [RouteHeaderUrl.subscribe_forms]: StripeStatsResponseItem
  [RouteHeaderUrl.upsell]: StripeStatsResponseItem
  [RouteHeaderUrl.sms]: StripeStatsResponseItem
}

@Injectable({providedIn: 'root'})

export class StripeLimitEnforcementService {
  public stripeSubscription: StripeSubscription
  private productPageConfig = new ProductPageConfig().config
  private subscription = new Subscription()
  public displayLimitsConfig$: BehaviorSubject<LimitItem[]> = new BehaviorSubject<LimitItem[]>(null)
  public period: {start: string, end: string}

  private featureConfig = {
    [RouteHeaderUrl.emails]: {
      limit: null,
      used: null,
      blocked: false,
      upgradeUrl: this.getUpgradeUrl(RouteHeaderUrl.emails),
    },
    [RouteHeaderUrl.subscribe_forms]: {
      limit: null,
      used: null,
      blocked: false,
      upgradeUrl: this.getUpgradeUrl(RouteHeaderUrl.subscribe_forms),
    },
    [RouteHeaderUrl.upsell]: {
      limit: null,
      used: null,
      blocked: false,
      upgradeUrl: this.getUpgradeUrl(RouteHeaderUrl.upsell),
    },
    [RouteHeaderUrl.sms]: {
      limit: null,
      used: null,
      blocked: false,
      upgradeUrl: `/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.sms}`,
    },
  }

  public feature$ = new BehaviorSubject<{[key: string]: FeatureStatus}>(null)

  constructor(
    private userService: UserService,
    private cartService: CartService,
    private apiService: ApiService,
    private apiDenoService: ApiDenoService,
  ) {
  }

  public init() {
    // Free stripe subscribers have no stripe subscription so we use the subscription dates from the userInfo
    this.stripeSubscription = this.userService?.stripeSubscription ?? {
      current_period_start: moment.utc(this.userService.userInfo?.subscription?.current_period_start_date).format('YYYY-MM-DD'),
      current_period_end: moment.utc(this.userService.userInfo?.subscription?.current_period_end_date).format('YYYY-MM-DD'),
    } as any
    let start = this.stripeSubscription?.current_period_start
    let end = this.stripeSubscription?.current_period_end
    if (typeof start === 'number' && typeof end === 'number') {
      // date can be in seconds or as a string
      start = start * 1000
      end = end * 1000
    }
    this.period = {
      start: moment.utc(start).format('YYYY-MM-DD'),
      end: moment.utc(end).format('YYYY-MM-DD'),
    }
    this.subscription.add(
      this.cartService.updatePurchasedProducts().pipe(
        // create config and set limits from productsPageConfig
        map(() => this.updateLimits(structuredClone(this.featureConfig))),
        switchMap((configWithLimits) => {
          // get usage stats and update config
          return this.getUsageStats(this.period.start, this.period.end).pipe(
            map((results) => {
              Object.keys(results).forEach(key => {
                if (key !== 'dbe_token') {
                  configWithLimits[key] = this.setUsageStats(configWithLimits[key], results[key])
                  // If the feature has no limit and no usage, it should be enabled
                  // That's a special case for users that have no plan yet
                  if (configWithLimits[key].used === 0 && configWithLimits[key].limit === 0) {
                    configWithLimits[key].blocked = false
                  }
                } else {
                  if (!this.userService.userInfo?.dbe_token && results[key]) {
                    this.updateDbeToken(results[key])
                  }
                }
              })
              // const userToBeMocked = this.userService.userInfo.id === '51af1538-a298-4676-8b18-7963cfce6040'
              // if (userToBeMocked) {
              //   configWithLimits[RouteHeaderUrl.upsell].used = 10000
              //   configWithLimits[RouteHeaderUrl.upsell].blocked = true
              // }
              return configWithLimits
            })
          )
        })
      ).subscribe({
        next: (results) => {
          this.feature$.next(results)
        }
      })
    )
  }

  private updateDbeToken(token: string): void {
    this.subscription.add(
      this.apiService.patch('/v1/me', {
        interactions: {
          dbe_token: token
        }
      }).subscribe({
        next: () => {
          const value = this.userService.interactions$.value
          if (value) {
            this.userService.interactions = {
              ...value,
              dbe_token: token
            }
            this.userService.interactions$.next({
              ...value,
              dbe_token: token
            })
          }
        }
      })
    )
  }

  private setUsageStats(featureStatus: FeatureStatus, stats: StripeStatsResponseItem) {
    return {
      ...featureStatus,
      used: stats?.used || 0,
      blocked: stats?.wl === true ? false : stats?.used >= featureStatus.limit,
    }
  }

  private getUpgradeUrl(feature?: RouteHeaderUrl): string {
    const item = this.productPageConfig.flatMap(product => product.sections).find(section => section.id === feature)
    const baseUrl = `/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}/${RouteHeaderUrl.custom}`
    if (item?.url) {
      return `${baseUrl}/${item.url}`
    } else {
      return `${baseUrl}`
    }
  }

  private updateLimits(features: {[key: string]: FeatureStatus}): {[key: string]: FeatureStatus} {
    const pageConfig = this.cartService.productsPageConfig$?.value
    const sections = pageConfig?.flatMap(c => c?.sections)
    Object.keys(features).forEach(feature => {
      const section = sections.find(section => section.id === feature)
      if (section) {
        const value = section.purchasedProduct?.quantity || section.purchasedProduct?.metadata?.quantity || section.selectedProduct?.metadata?.quantity
        features[feature].limit = this.getNumber(value)
        // Special treatment for SMS credits
        const smsCreditsPurchased = this.userService.smsCreditsPurchased || 0
        if (feature === RouteHeaderUrl.sms) {
          features[feature].limit = smsCreditsPurchased
        }
      }
    })
    return features
  }

  public getUsageStats(start: string, end: string): Observable<StripeStatsResponse> {
    return this.apiDenoService.get<StripeStatsResponse>(`usage/stats?start_date=${start}&end_date=${end}`)
  }

  private getNumber(value: any): number {
    return typeof value === 'number' ? value : Number(value) || 0
  }

}
