<div class="at" [class._opened]="opened">
  <div class="at-button" (click)="opened = !opened">
    <hr class="at-button-icon">
  </div>
  <div class="at-body">
    <div class="at-title">Admin Tools</div>
    <div class="at-info">
    @for(item of userData; track 'key') {
      <div class="at-info-key">
        {{item?.title}}:
      </div>
      <div class="at-info-value {{item?.type}}" (click)="copyToClipboard(item?.value)">
        @switch (item?.type) {
          @case ('link') {
            <a href="{{item?.value}}" target="{{item?.target}}">{{item?.value}}</a>
          }
          @case ('info') {
            <ng-container 
              [ngTemplateOutlet]="copy"
            ></ng-container>
            {{item?.value}}
          }
        }
      </div>
    }
    </div>

    <hr>

    @if (!isStripeCustomer) {
      <button class="one-button outline black xs w-100 mt-1" 
        [routerLink]="[RouteHeaderUrl.settings + '/' + RouteHeaderUrl.billing + '/' + RouteHeaderUrl.subscriptions]"
        [queryParams]="{fv: '0', ffp: 'true'}"
      >Show Plans With Free Option</button>

      <button class="one-button black xs w-100 mt-1" 
        [class.outline]="!stripePricingForced"
        [class.filled]="stripePricingForced"
        (click)="toggleStripePricing()"
      >{{ stripePricingForced ? 'Stop Forcing Stripe Pricing' : 'Force Stripe Pricing' }}</button>
    }

    <hr>

    <button class="one-button outline black xs w-100 mt-1" 
      [routerLink]="[RouteHeaderUrl.admin_panel + '/' + RouteHeaderUrl.affiliate_links]"
    >Affiliate Links</button>
    <button class="one-button outline black xs w-100 mt-1" 
      [routerLink]="[RouteHeaderUrl.admin_panel + '/' + RouteHeaderUrl.enforcement]"
    >White List Settings</button>
    <button class="one-button outline black xs w-100 mt-1" 
      (click)="showMeResponse()"
    >User Info Object</button>

    <hr>

    <button class="one-button outline black xs w-100 mt-1"
      (click)="removeLoadingOverlay()"
    >Remove Loading Overlay</button>

    <button class="one-button filled black xs w-100 mt-1"
      [class.outline]="!consoleOutputEnabled"
      [class.filled]="consoleOutputEnabled"
      (click)="toggleConsoleOutput()"
    >{{ consoleOutputEnabled ? 'Disable Console Output' : 'Enable Console Output'}}</button>
    
    <hr>

    <button class="one-button red xs w-100 mt-1"
      [class.outline]="!navigationOverridden"
      [class.filled]="navigationOverridden"
      (click)="allowNavigation()"
    >{{navigationOverridden ? 'Stop Overriding Navigation' : 'Allow Navigation'}}</button>

    <button class="one-button filled red xs w-100 mt-1"
      (click)="logoutAsAdmin()"
    >Admin Logout</button>
  </div>
</div>

<ng-template #copy>
  <div class="at-info-copy">
    <img src="/app_assets/copy-icon.png" alt="copy" class="copy-icon">
    <img src="/app_assets/checkmark-icon.png" alt="done" class="check-icon">
  </div>
</ng-template>